import { BorderBtn } from '@playbooks/interface/buttons';
import { BorderLink } from '@playbooks/interface/links';
import { RepoBadges } from 'components/repo/repo-badges';
import { RepoCardActions } from 'components/repo/repo-card-actions';
import { RepoCardMeta } from 'components/repo/repo-card-meta';
import {
	ActionCard,
	DetailCard,
	DisplayCard,
	FeaturedCard,
	GridCard,
	InfoCard,
	ListCard,
	PhotoLinkCard,
	PillCard,
	PreviewCard,
	PreviewLinkCard,
	SearchBtnCard,
	SelectCard,
} from 'molecules/cards';
import { formatDate } from 'utils';

export const RepoActionCard = ({ repo, loading, children, tailwind }) => {
	// Render
	return (
		<ActionCard
			icon='code'
			photo={repo.thumbnail}
			status={repo.tier}
			title={repo.name}
			subtitle={repo.tagline}
			meta={<RepoCardMeta repo={repo} />}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</ActionCard>
	);
};

export const RepoDetailCard = ({ repo, loading, tailwind }) => {
	// Render
	return (
		<DetailCard
			icon='code'
			cover={repo.cover}
			photo={repo.thumbnail}
			pretitle={repo.variant}
			title={repo.name}
			subtitle={repo.uuid}
			text={repo.tagline || '--'}
			footer={repo.updatedAt}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const RepoDisplayCard = ({ repo, rootLink, children, tailwind }) => {
	// Render
	return (
		<DisplayCard
			icon='code'
			photo={repo.thumbnail}
			badges={<RepoBadges repo={repo} />}
			pretitle={repo.variant}
			title={repo.name}
			text={repo.tagline}
			meta={<RepoCardMeta repo={repo} />}
			href={`${rootLink}/repos/${repo.uuid}`}
			tailwind={tailwind}>
			{children ? children : <RepoCardActions type='display' repo={repo} />}
		</DisplayCard>
	);
};

export const RepoFeaturedCard = ({ repo, loading, rootLink, tailwind }) => {
	// Render
	return (
		<FeaturedCard
			icon='code'
			photo={repo.thumbnail}
			title={repo.name}
			subtitle={repo.tagline}
			href={`${rootLink}/repos/${repo.uuid}`}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const RepoGridCard = ({ repo, rootLink, children, tailwind }) => {
	// Render
	return (
		<GridCard
			icon='code'
			photo={repo.cover}
			badges={<RepoBadges repo={repo} />}
			pretitle={repo.variant}
			title={repo.name}
			text={repo.tagline}
			href={`${rootLink}/repos/${repo.uuid}`}
			tailwind={tailwind}>
			{children ? children : <RepoCardActions type='display' repo={repo} />}
		</GridCard>
	);
};

export const RepoInfoCard = ({ repo, loading, tailwind }) => {
	// Render
	return (
		<InfoCard
			icon='code'
			photo={repo.thumbnail}
			status={repo.status}
			pretitle={repo.variant}
			title={repo.name}
			subtitle={repo.tagline}
			meta={<RepoCardMeta repo={repo} />}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const RepoListCard = ({ repo, loading, rootLink, tailwind }) => {
	// Render
	return (
		<ListCard
			icon='code'
			badges={<RepoBadges repo={repo} size='xs' />}
			photo={repo.thumbnail}
			title={repo.name}
			subtitle={repo.tagline}
			meta={<RepoCardMeta repo={repo} />}
			href={`${rootLink}/repos/${repo.uuid}`}
			tailwind={tailwind}>
			<RepoCardActions type='list' repo={repo} />
		</ListCard>
	);
};

export const RepoPillCard = ({ repo, loading, rootLink, children, tailwind }) => {
	// Render
	return (
		<PillCard
			icon='code'
			photo={repo.thumbnail}
			title={repo.name}
			text={repo.tagline}
			href={`${rootLink}/repos/${repo.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</PillCard>
	);
};

export const RepoPhotoLinkCard = ({ repo, loading, rootLink, children, tailwind }) => {
	// Render
	return (
		<PhotoLinkCard
			icon='code'
			photo={repo.thumbnail}
			title={repo.name}
			text={repo.tagline}
			href={`${rootLink}/repos/${repo.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</PhotoLinkCard>
	);
};

export const RepoPreviewCard = ({ repo, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PreviewLinkCard
			icon='code'
			photo={repo.thumbnail}
			title={repo.name}
			text={repo.uuid}
			href={`${rootLink}/repos/${repo.uuid}`}
			tailwind={tailwind}
		/>
	);
};

export const RepoPreviewActionCard = ({ repo, loading, taskRunning, onClick, rootLink, tailwind }) => {
	// Render
	return (
		<PreviewCard
			icon='code'
			photo={repo.thumbnail}
			title={repo.name}
			text={repo.tagline}
			href={`${rootLink}/repos/${repo.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{onClick && (
				<BorderBtn
					size='icon'
					icon='badge-check'
					active={repo.featured}
					taskRunning={repo.id === taskRunning.id}
					onClick={() => onClick(repo)}
				/>
			)}
			<BorderLink size='icon' icon='chevron-right' href={`${rootLink}/repos/${repo.uuid}`} />
		</PreviewCard>
	);
};

export const RepoSearchCard = ({ repo, onClick, tailwind }) => {
	// Render
	return (
		<SearchBtnCard
			icon='code'
			modelName='repo'
			badges={<RepoBadges size='xs' repo={repo} />}
			photo={repo.thumbnail}
			title={repo.name}
			subtitle={repo.tagline}
			onClick={() => onClick(repo)}
			tailwind={tailwind}
		/>
	);
};

export const RepoSelectCard = ({ repo, selected = [], taskRunning, onClick, tailwind }) => {
	// Render
	return (
		<SelectCard
			icon='code'
			badges={<RepoBadges size='xs' repo={repo} />}
			status={repo.variant}
			photo={repo.thumbnail}
			title={repo.name}
			subtitle={repo.owner?.name}
			selected={selected.map(v => v.id).includes(repo.id)}
			taskRunning={repo.id === taskRunning}
			onClick={() => onClick(repo)}
			tailwind={tailwind}
		/>
	);
};

export const RepoSubmitCard = ({ repo, taskRunning, onClick, tailwind }) => {
	// Render
	return (
		<ActionCard
			icon='code'
			badges={<RepoBadges size='xs' repo={repo} />}
			status={repo.status}
			photo={repo.thumbnail}
			pretitle={repo.variant}
			title={repo.name}
			text={'Last updated ' + formatDate(repo.updatedAt)}
			tailwind={tailwind}>
			<RepoCardActions type='submit' repo={repo} taskRunning={taskRunning} onClick={onClick} />
		</ActionCard>
	);
};
